import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import GravityFormForm from 'gatsby-plugin-gravity-forms'

const ContactForm = () => {

    const data = useStaticQuery(graphql`
        query ContactQuery {
            wpGfForm(databaseId: { eq: 1 }) {
                ...GravityFormFields
            }
        }
    `)


    return (
        <div className="container">
            <GravityFormForm data={data.wpGfForm}/>
        </div>
    )
}

export default ContactForm